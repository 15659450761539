import teacher1 from "../../img/teachers/teacher-1.webp";
import teacher2 from "../../img/teachers/teacher-2.webp";
import teacher3 from "../../img/teachers/teacher-3.webp";
import teacher4 from "../../img/teachers/teacher-4.webp";
import teacher5 from "../../img/teachers/teacher-5.webp";
import teacher7 from "../../img/teachers/teacher-7.webp";
import teacher8 from "../../img/teachers/teacher-8.webp";

export const TeacherInfo = [
  {
    id: 1,
    modal: "teacher1",
    name: "Сидорова Ирина Леонидовна",
    img: teacher1,
    post: "Руководитель онлайн школы «Shine School»",
    info: [
      "Меня зовут Сидорова Ирина, и я руководитель онлайн школы английского языка «Shine School».",
      "Наша школа создана для того, чтобы сделать обучение английскому языку удобным, доступным и интересным. Мы работаем с детьми и взрослыми и твердо уверены, что нет людей, неспособных к языкам!",
      "Успех нашей онлайн школы состоит из следующих компонентов:",
      "· профессиональные преподаватели, получающие удовольствие от своей работы,",
      "· «Живое» общения преподавателя и группы,",
      "· уникальная методика обучения, проверенная долгим опытом (более 15 лет офлайн и онлайн работы),",
      "· аутентичные учебные пособия,",
      "· использование современных технологий (мультимедиа, электронные учебные пособия и т.д.),",
      "· демократичные цены и возможность выбора удобного пакета оплаты.",
      "Мы твердо уверены, что онлайн обучение может быть качественным, результативным и доступным! Хотите в этом убедится? Присоединяйтесь к нам!",
    ],
  },
  {
    id: 2,
    modal: "teacher2",
    name: "Кан Ирина Олеговна",
    img: teacher2,
    post: "Aкадемический директор, методист онлайн школы «Shine School»",
    info: [
      "Я преподаю английский язык уже почти 20 лет и нежно люблю свою профессию. За это время я работала с учениками разного возраста и в разном формате: в вузе со студентами, с детьми от 5 лет в частной школе, с подростками и взрослым в языковых центрах и онлайн.",
      "Мой профиль — подготовка к международным экзаменам и ЕГЭ. Мои ученики успешно сдают кембриджские экзамены (PET, FCE, Business English Certificate), а также ЕГЭ на 80+. Не передать словами мою радость, когда мои ученики добиваются таких высоких результатов!",
      "Имею диплом преподавателя английского языка с отличием по специальности лингвистика, сертификаты международных экзаменов TOEFL, TOEIC С1(Advanced).",
      "Прошла курс обучения в языковой школе в Лондоне, участница American English E-Teacher Program. Прохожу международную сертификацию по методике преподавания TESOL (Arizona State University). Очень люблю учиться и учить онлайн и по опыту знаю, что каждый может достичь своей цели с помощью онлайн-обучения и открыть для себя мир без границ!",
    ],
  },
  {
    id: 3,
    modal: "teacher3",
    name: "Марина Викторовна",
    img: teacher3,
    post: "Педагог онлайн школы «Shine School»",
    info: [
      "Привет! Я действующий преподаватель китайского языка с 2015 года.",
      "Получила педагогическое образование с двумя профилями подготовки: китайский язык и английский язык. Жила и работала в Китае; практиковала язык в Сингапуре, Малайзии и Тайланде. Обучаю с нуля детей от 8-10 лет, подростков и взрослых. Готовлю к HSK. Я точно знаю, как с легкостью и юмором показать, что писать иероглифы и болтать на китайском не так уж и страшно!;)",
    ],
  },
  {
    id: 4,
    modal: "teacher4",
    name: "Вероника Сергеевна",
    img: teacher4,
    post: "Педагог онлайн школы «Shine School»",
    info: [
      "Окончила Пятигорский государственный университет в 2018, изучала английский и китайский языки, также проходила языковые курсы китайского в 2017 году в Шанхайском университете иностранных языков, множество онлайн курсов по HSK и т.д.",
      "Преподаю иностранные языки вот уже 6 лет, как оффлайн, так и онлайн. Два года работала в Китае преподавателем, а также во множество онлайн школах, продолжаю свою деятельность до сих пор и получаю от нее огромное удовольствие! Приходите ко мне на занятия китайского, где Вы сами сможете убедиться насколько интересным можно процесс изучения нового языка!",
    ],
  },
  {
    id: 5,
    modal: "teacher5",
    name: "Мария Васильевна",
    img: teacher5,
    post: "Педагог онлайн школы «Shine School»",
    info: [
      "你好！我是玛丽老师。Провожу онлайн-занятия для детей и взрослых. Основной акцент во всех занятиях — на разговорной практике. Да, даже маленькие детки учатся говорить по-китайски с первых минут занятия. ",
      "Программу подстраиваю индивидуально под желания ученика. Туда входят: аудио- и видео-материалы, задания на отработку грамматики, на работу с видео. Программа основана на учебных материалах — HSK Standard Course — для взрослых; — YCT — для детей от 5 до 15 лет. ",
      "Опыт преподавания более 4-х лет. Работаю с дошкольниками, подростками и взрослыми. Со мной взрослые ученики изучают китайский язык для себя, чтобы подтянуть программу на языковом факультете. Дети поступают в школу с китайским уклоном и уже опережают основную программу. ",
      "В 2018 году закончила Южно-уральский государственный университет по специальности лингвистика и международные отношения. Училась в Китае в Zhejiang Ocean University. Есть подтвержденный сертификат HSK5.",
    ],
  },
  // {
  //     id: 6,
  //     modal: 'teacher6',
  //     name: 'Екатерина Игоревна',
  //     img: teacher6,
  //     post: 'Педагог онлайн школы «Shine School»',
  //     info: [
  //         '你好！Я преподаю китайский язык с 2020 года. Самым важным в своей работе считаю отношение к ученику, как к личности: внимание к его интересам, целям, предпочтениям. ',
  //         'Работала индивидуально и в группах, с дошкольниками и более взрослыми учениками. ',
  //         'В 2022 году получила образование по специальности «регионовед Китая» - часто включаю в свои занятия культурные и исторические справки о стране, что позволяет обучающимся лучше понимать менталитет и логику носителей языка. Заканчиваю магистратуру по методике преподавания китайского языка. Также имею опыт очного и дистанционного обучения в университетах Китая (Сычуаньский университет иностранных языков и университет Циндао). Пишу научные работы на тему грамотного и современного обучения фонетике - наиважнейшего аспекта китайского языка, который часто недооценивается.',
  //         'Самым важным в своей работе считаю отношение к ученику, как к личности: внимание к его интересам, целям, предпочтениям не только помогает мне создавать более качественные уроки, но и позволяет каждому чувствовать себя комфортно и не бояться делать ошибки. Атмосфера на моих занятиях комфортная и дружелюбная (:',
  //     ],
  // },
  {
    id: 7,
    modal: "teacher7",
    name: "Инна Геннадьевна",
    img: teacher7,
    post: "Педагог онлайн школы «Shine School»",
    info: [
      "🙌 Немного расскажу о себе ⬇️      ➡️ Меня зовут Инна (китайское имя Shen Ying)",
      '➡️ Я окончила Новосибирский государственный педагогический университет по специальности "Культурология и китайский язык"🇨🇳',
      '➡️ Проходила обучение в Шеньянском политехническом университете 沈阳理工大学 на курсе "Китайский язык для иностранцев" (КНР, провинция ЛяоНин, г.ШэньЯн,).',
      "➡️ Выйграла конкурс и стажировку в Шаньдунский Педагогический университет 山东师范大学 (КНР, провинция ШаньДун, г.Цзинань).",
      "🗣 Жила и работала в КНР на протяжении нескольких лет (переводчик, преподаватель русского и английского языков в школе иностранных языков 杨格外语学校).",
      "👉 В настоящее время обучаю китайскому языку детей от 10 лет и взрослых 🫡🇨🇳",
      "📚 В обучении использую различные учебные пособия, подстраивая программу обучения под потребности каждого ученика. На уроках уделяю внимание каждому аспекту изучения языка: аудирование, чтение, письмо.",
    ],
  },
  {
    id: 8,
    modal: "teacher8",
    name: "Юлия Ивановна",
    img: teacher8,
    post: "Педагог онлайн школы «Shine School»",
    info: [
      "大家好！Меня зовут Юлия, я профессиональный репетитор китайского языка. Имею два профильных образования, была в Китае и знаю тонкости языка. ",
      "Я помогу тебе освоить китайский язык быстро и эффективно, а также расскажу о современной стороне Китая. Я буду не только преподавателем для тебя, но и другом, который поддержит твои начинания в изучении такого сложного, на первый взгляд, языка. Занятия со мной будут проходить в комфортной для тебя обстановке, во время которых ты можешь задать мне любые вопросы, касающиеся языка и культуры.",
      "Давай начнем твое увлекательное путешествие в мир китайского языка уже сегодня! 加油！",
    ],
  },
];
