import React, { useState } from "react";
import "./css/style.css";

// 1. Импортируем React Router
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Header from "./components/header";
import Main from "./components/main";
import Footer from "./components/Footer";

// Подключаем компонент с формой оплаты, чтобы сделать роут /payment
import PaymentForm from "./components/payForm";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    console.log("Opening modal");
    setIsModalOpen(true);
    document.body.style.overflow = "hidden"; // Отключаем скроллинг
  };

  const closeModal = () => {
    console.log("Closing modal");
    setIsModalOpen(false);
    document.body.style.overflow = "auto"; // Включаем скроллинг обратно
  };
  // return (
  //   <div className="app">
  //     <Header onOpenModal={openModal} />
  //     <Main
  //       isModalOpen={isModalOpen}
  //       onCloseModal={closeModal}
  //       onOpenModal={openModal}
  //     />
  //     <Footer />
  //   </div>
  // );
  // 2. Оборачиваем всё в <Router>, внутри которого рендерим <AppContent />
  return (
    <Router>
      <AppContent
        isModalOpen={isModalOpen}
        openModal={openModal}
        closeModal={closeModal}
      />
    </Router>
  );
}

// 3. Доп. компонент, который проверяет, где мы находимся (useLocation),
// и при pathname === '/payment' убирает Header и Footer
function AppContent({ isModalOpen, openModal, closeModal }) {
  const location = useLocation();
  // true, если текущий адрес /payment
  const isPaymentPage = location.pathname === "/payment";

  return (
    <div className="app">
      {" "}
      {/* Сохраняем ваш класс .app */}
      {/* Если НЕ страница оплаты, показываем хедер */}
      {!isPaymentPage && <Header onOpenModal={openModal} />}
      {/* Здесь объявляем роуты для вашего приложения */}
      <Routes>
        {/* Основная страница (раньше вы Main рендерили напрямую) */}
        <Route
          path="/"
          element={
            <Main
              isModalOpen={isModalOpen}
              onCloseModal={closeModal}
              onOpenModal={openModal}
            />
          }
        />
        {/* Страница оплаты */}
        <Route path="/payment" element={<PaymentForm />} />
      </Routes>
      {/* Если НЕ страница оплаты, показываем футер */}
      {!isPaymentPage && <Footer />}
    </div>
  );
}

export default App;
