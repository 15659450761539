import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import arrow from "../img/arrow_left.svg";
import wavesKids2 from "../img/waves5.svg";

function PaymentForm() {
  // Проверка userAgent
  const isIPhone = /iPhone/i.test(navigator.userAgent);

  // Узнаём, в каком роуте мы сейчас
  const location = useLocation();
  const navigate = useNavigate();

  // Проверяем, совпадает ли путь с "/payment"
  const isSeparatePage = location.pathname === "/payment";

  useEffect(() => {
    // Явно скроллим наверх при монтировании
    window.scrollTo(0, 0);
  }, []);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isNameForbidden, setIsNameForbidden] = useState(false);

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      const apiUrl = "/payment";

      // Явно задаем значения для скрытых полей перед отправкой формы
      const requestData = {
        amount: values.amount,
        name: values.name,
        email: values.email,
        phone: values.phone,
        purpose: values.purpose || "Оплата курсов по китайскому языку",
        return_url: values.return_url || "https://chinese.shineschool.ru/",
      };

      if (isNameForbidden) {
        setStatus({ success: true });
      } else {
        const response = await axios.post(apiUrl, requestData);
        console.log(response.data);
        const payLink = response.data.confirmation.confirmation_url;
        if (payLink) {
          setStatus({ success: true });
          window.location.href = payLink;
        } else {
          setStatus({ success: false });
          console.error("Error creating payment", response.data.message);
        }
      }
    } catch (error) {
      console.error("Error sending payment", error);
      setStatus({ success: false });
    }
  };

  const forbiddenNames = ["пидор", "сука", "pidor", "suka", "жопа", "zhopa"];

  const forbiddenPhoneNumbers = [
    "+7(111) 111-11-11",
    "+7(999) 999-99-99",
    "+7(123) 456-78-90",
    "+7(098) 765-43-21",
    "+7(222) 222-22-22",
    "+7(333) 333-33-33",
    "+7(444) 444-44-44",
    "+7(555) 555-55-55",
    "+7(666) 666-66-66",
    "+7(777) 777-77-77",
    "+7(888) 888-88-88",
    "81111111111",
    "89999999999",
    "81234567890",
    "80987654321",
    "82222222222",
    "83333333333",
    "84444444444",
    "85555555555",
    "86666666666",
    "87777777777",
    "88888888888",
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("*Заполните поле")
      .matches(
        /^[A-Za-zА-Яа-яЁё\s]+$/,
        "*Имя не может содержать цифры или символы"
      )
      .test("forbidden-name", "*Введите корректное имя", (value) => {
        if (forbiddenNames.includes(value)) {
          setIsNameForbidden(true);
          return true;
        }
        setIsNameForbidden(false);
        return true;
      }),
    amount: Yup.number().required("*Заполните поле"),
    phone: Yup.string()
      .required("*Заполните поле")
      .matches(
        /^8\d{10}$/,
        "*Введите корректный российский номер телефона, начинающийся с 8 и содержащий 11 цифр"
      )
      .notOneOf(
        forbiddenPhoneNumbers,
        "*Введите корректный российский номер телефона"
      ),
    email: Yup.string()
      .email("*Введите корректный e-mail")
      .required("*Заполните поле"),
  });

  const formatPhone = (value) => {
    const digits = value.replace(/\D/g, ""); // Убираем все нечисловые символы
    if (digits.startsWith("8")) {
      return digits.slice(0, 11); // Ограничиваем до 11 цифр
    } else if (digits.startsWith("7")) {
      return "8" + digits.slice(1, 11); // Заменяем первую цифру 7 на 8, если номер начинается с 7
    } else {
      return "8" + digits.slice(0, 10); // Если ввод начался с другой цифры, начинаем с 8
    }
  };

  // Обработчик для кнопки "Назад"
  const handleGoBack = () => {
    navigate(-1); // Возвращаемся на предыдущую страницу
  };

  return (
    <Formik
      initialValues={{
        amount: "",
        name: "",
        purpose: "Оплата курсов по китайскому языку",
        phone: "",
        email: "",
        return_url: "https://chinese.shineschool.ru/",
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors = {};
        if (!values.amount) errors.amount = "*Заполните поле";
        if (!values.name) errors.name = "*Заполните поле";
        if (!values.phone || !/^8\d{10}$/.test(values.phone)) {
          errors.phone =
            "*Введите корректный российский номер телефона, начинающийся с 8 и содержащий 11 цифр";
        }
        if (!values.email) errors.email = "*Заполните поле";
        setIsFormValid(Object.keys(errors).length === 0);
        return errors;
      }}
    >
      {({ isSubmitting, status, values, setFieldValue, isValid }) => (
        <Form
          className="main__section__form__layout payment-iphone-class"
          // className="main__section__form__layout"
        >
          {/* Если открыта отдельная страница /payment,
              показываем кнопку "Назад" */}
          {isSeparatePage && (
            <button
              type="button"
              onClick={handleGoBack}
              className="payment-iphone-class__backButton"
            >
              <img
                src={arrow}
                alt="advantages"
                className="payment-iphone-class__backButton__arrow"
              />
              Вернуться назад
            </button>
          )}
          <p className="main__section__form__layout__text">
            Оплата курсов по китайскому языку
          </p>
          <div className="main__section__form__layout__item">
            <Field
              type="number"
              id="amount"
              placeholder="Укажите сумму в RUB"
              name="amount"
              className="main__section__form__layout__item__input"
            />
            <ErrorMessage
              name="amount"
              component="div"
              className="main__section__form__layout__item__error"
            />
          </div>
          <div className="main__section__form__layout__item">
            <Field
              type="text"
              id="name"
              placeholder="ФИО"
              name="name"
              className="main__section__form__layout__item__input"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="main__section__form__layout__item__error"
            />
          </div>
          <div className="main__section__form__layout__item">
            <Field
              name="phone"
              className="main__section__form__layout__item__input"
              placeholder="Введите номер телефона"
              value={values.phone}
              onChange={(e) =>
                setFieldValue("phone", formatPhone(e.target.value))
              }
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="main__section__form__layout__item__error"
            />
          </div>
          <div className="main__section__form__layout__item">
            <Field
              type="email"
              id="email"
              placeholder="e-mail"
              name="email"
              className="main__section__form__layout__item__input"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="main__section__form__layout__item__error"
            />
          </div>
          <Field type="hidden" id="return_url" name="return_url" />
          <Field type="hidden" id="purpose" name="purpose" />
          <button
            type="submit"
            disabled={isSubmitting || !isFormValid}
            className="main__section__form__layout__button"
          >
            Перейти к оплате
          </button>
          {isSeparatePage && (
            <img
              src={wavesKids2}
              alt="img"
              className="payment-iphone-class__waves"
            />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default PaymentForm;
